import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "@/assets/styles/tailwind.css";
import "@/assets/styles/livelike.css";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

const requireComponent = require.context(
  "./components",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n);

app.config.isCustomElement = tag => tag === 'livelike-chat';

// Add Base Components globally
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );
  app.component(componentName, componentConfig.default || componentConfig);
});

app.mount("#app");
