<template>
  <div
    v-if="!validated"
    class="bg-violet-200 px-4 py-6 w-full shadow rounded-lg sm:overflow-hidden"
  >
    <h3 class="text-sm font-semibold text-white">
      {{ $t("nickname.title") }}
    </h3>
    <form @submit.prevent="submitWithName">
      <div class="mt-5">
        <Label>{{ $t("nickname.label") }}</Label>
        <input
          ref="input"
          class="mt-1 form-input block w-full border border-solid border-gray-300 text-gold-100 px-5 text-sm sm:text-md leading-10 rounded-lg focus:rounded-lg bg-white bg-opacity-10"
          @input="$emit('input', $event.target.value)"
        />
        <div v-if="error" class="text-red">
          {{ $t("nickname.error.name.required") }}
        </div>
      </div>
      <div class="mt-5">
        <Button type="submit" size="lg" color="white" :fullwidth="true">{{
          $t("nickname.submit")
        }}</Button>
      </div>
      <!--h3 class="mt-63px text-13px font-semibold text-white">Continuer sans le chat</h3>
          <div class="mt-16px mb-22px">
              <Button type="button" size="lg" color="white" :fullwidth=true @click="submitWithoutName">Valider</Button>
          </div-->
    </form>
  </div>
</template>
<script>
import Button from "@/components/Button.vue";
import Label from "@/components/Label.vue";
export default {
  components: {
    Button,
    Label
  },
  props: {
    validated: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click", "validate", "input"],
  data() {
    return {
      inputValue: "",
      error: false
    };
  },
  methods: {
    submitWithName() {
      this.error = false;

      if (!this.$refs.input.value) {
        this.error = true;
        this.$refs.input.focus();

        return;
      }

      this.$emit("validate", true, this.$refs.input.value);
    },
    submitWithoutName() {
      this.$emit("validate", true);
    },
    click(data) {
      this.$emit("click", data);
    }
  }
};
</script>
