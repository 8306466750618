<template>
  <nav :class="!transparent ? 'bg-white' : ''">
    <div :class="{ 'container mx-auto': container }">
      <div class="flex justify-between">
        <router-link :to="{ name: 'Home' }" class="p-2">
          <img
            v-if="transparent"
            class="w-32 sm:w-40 inline"
            src="../assets/img/aplat-complet-blanc.svg"
          />
          <img
            v-else
            class="w-32 sm:w-40 inline"
            src="../assets/img/aplat-complet-noir.svg"
          />
        </router-link>

        <div :class="transparent ? 'text-white' : ''">
          <slot>
            <div class="pt-8 sm:pt-10">
              <SearchIcon class="inline mx-5 align-middle" size="20" />
              <SelectLocale />
            </div>
          </slot>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { SearchIcon } from "@zhuowenli/vue-feather-icons";
import SelectLocale from "@/components/SelectLocale.vue";

export default {
  name: "NavBar",
  components: {
    SearchIcon,
    SelectLocale
  },
  props: {
    transparent: {
      type: Boolean,
      default: false
    },
    container: {
      type: Boolean,
      default: true
    }
  }
};
</script>
