<template>
  <div
    v-if="open"
    class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-20"
  >
    <div
      class="absolute w-full h-full bg-gray-900 opacity-50"
      @click="close"
    ></div>

    <div class="absolute max-h-full" :class="maxWidth">
      <div class="container bg-white overflow-hidden md:rounded-xl">
        <div
          v-if="showHeader"
          class="px-4 py-4 leading-none flex justify-between items-center font-medium text-sm bg-gray-100 border-b select-none"
        >
          <h3>{{ title }}</h3>
          <div
            class="text-2xl hover:text-gray-600 cursor-pointer"
            @click="close"
          >
            &#215;
          </div>
        </div>

        <div class="max-h-full px-4 py-4">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: true
    },
    width: {
      type: String,
      default: "lg",
      validator: value => ["xs", "sm", "md", "lg", "full"].indexOf(value) !== -1
    }
  },
  emits: ["close"],
  data() {
    return {
      open: true
    };
  },
  computed: {
    maxWidth() {
      switch (this.width) {
        case "xs":
          return "max-w-lg md:w-xl";
        case "sm":
        default:
          return "max-w-xl md:w-xl";
        case "md":
          return "max-w-2xl md:w-2xl";
        case "lg":
          return "max-w-3xl md:w-3xl";
        case "full":
          return "max-w-full";
      }
    }
  },
  mounted() {
    const onEscape = e => {
      if (e.key === "Esc" || e.key === "Escape") {
        this.close();
      }
    };

    document.addEventListener("keydown", onEscape);

    /* once hook needs updating for Vue 3. 
      this.$once("hook:beforeDestroy", () => {
        document.removeEventListener("keydown", onEscape);
      });
      */
  },
  methods: {
    close() {
      this.open = false;
      this.$emit("close");
    }
  }
};
</script>
