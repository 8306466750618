<template>
  <div class="bg-gray-800 bg-center bg-cover min-h-screen">
    <NavBar :container="false">
      <div class="pt-8 sm:pt-10 px-5 font-lg italic text-violet-100">
        {{ $t("backstage.nav.title") }}
      </div>
    </NavBar>
    <SuspenseWithError>
      <template #default>
        <AlertMessage
          v-if="
            ticket.type === 'ROOM' || ticket.type === 'STD' || error != null
          "
          type="danger"
        >
          <template #message>
            <slot name="error">
              <strong>{{ $t("backstage.error.message1") }}</strong
              >.
              {{ $t("backstage.error.message2") }}
            </slot>
          </template>
        </AlertMessage>
        <ticket-provider v-else :ticket="ticket" />
      </template>
      <template #fallback>
        <span>{{ $t("live.loading") }}</span>
      </template>
      <template #error>
        <slot name="error">{{ error }}</slot>
      </template>
    </SuspenseWithError>
  </div>
</template>
<script>
import TicketProvider from "@/components/tickets/TicketProvider.vue";
import SuspenseWithError from "@/components/SuspenseWithError.vue";
import NavBar from "@/components/NavBar.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import useTickets from "@/composables/use-tickets";
import router from "@/router";
import { onMounted } from "vue";
export default {
  components: {
    SuspenseWithError,
    NavBar,
    TicketProvider,
    AlertMessage
  },
  setup() {
    const { getTicket, ticket, error } = useTickets();
    const { currentRoute } = router;
    onMounted(async () => await getTicket(currentRoute.value.params.id));
    return { ticket, error };
  }
};
</script>