import repositoryFactory from "repository-factory";
import { ref } from "vue";

export default function useEvents() {
  const event = ref({});
  const events = ref({});
  const error = ref(null);

  function getEvents() {
    repositoryFactory
      .get("event")
      .getEvents()
      .then(data => (events.value = data.data.getEvents))
      .catch(e => {
        error.value = e.message;
      });
  }

  function getEvent(id) {
    repositoryFactory
      .get("event")
      .getEvent(id)
      .then(data => {
        event.value = data.data.getEventByID;
        if (!data.data.getEvent) {
          error.value = "No data found";
        }
      })
      .catch(e => {
        error.value = e.message;
      });
  }

  function getEventBySlug(slug) {
    repositoryFactory
      .get("event")
      .getEventBySlug(slug)
      .then(data => {
        event.value = data.data.getEventBySlug;
        if (!data.data.getEventBySlug) {
          error.value = "No data found";
        }
      })
      .catch(e => {
        error.value = e.message;
      });
  }

  return { getEvents, getEvent, getEventBySlug, event, events, error };
}
