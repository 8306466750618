import gql from "graphql-tag";

export const ALL_EVENTS_QUERY = gql`
  query getEvents {
    getEvents {
      id
      slug
      title
      date
      image
      promoted
      new
    }
  }
`;

export const EVENT_QUERY = gql`
  query getEventByID($id: String!) {
    getEventByID(id: $id) {
      id
      slug
      title
      description
      date
      image
      video
    }
  }
`;

export const EVENT_BY_SLUG_QUERY = gql`
  query getEventBySlug($slug: String!) {
    getEventBySlug(slug: $slug) {
      id
      slug
      title
      performer
      description
      date
      image
      video
      offers {
        id
        type
        name
        summary
        description
        price
      }
      social {
        hashtag
        channels {
          url
          channel
        }
      }
    }
  }
`;
