<template>
  <div
    :id="'video-' + participantId"
    :class="
      'video mx-auto' + (isLocal ? ' local' : '') + (bubble ? ' bubble' : '')
    "
  >
    <div :id="participantId" class="relative" :class="participantId">
      <video
        :id="'native-video-' + participantId"
        :ref="video"
        :key="'video-' + participantId"
        :muted="isLocal"
        :srcObject="stream"
        autoPlay
        disablePictureInstreamPicture
        playsInline
        :class="videoClass + (bubble ? ' bubble' : '')"
      />
      <img
        v-if="bubble"
        src="../assets/img/aplat-bulle-gris.png"
        class="absolute top-0 left-0 bubble"
      />
      <div
        v-if="participantName"
        class="absolute bottom-6 md:bottom-8 left-0 right-0 text-white text-center text-xs"
      >
        {{ participantName }}
      </div>
      <div
        v-if="showLogo && !isLocal"
        class="absolute bottom-1 sm:bottom-3 right-3 lg:right-5 "
      >
        <img
          class="w-20 sm:w-24 lg:w-32"
          src="../assets/img/aplat-complet-blanc.svg"
        />
      </div>
      <template v-if="isLocal">
        <div class="absolute bottom-0 md:bottom-1 left-0 right-0 text-white text-center">
          <button
            type="button"
            :class="'mr-3 ' + (isVideoEnabled ? 'videoOff' : 'videoOn')"
            @click.prevent="toggleVideo()"
          >
            <template v-if="isVideoEnabled">
              <video-off-icon size="1.0x"></video-off-icon>
            </template>
            <template v-else>
              <video-icon size="1.0x"></video-icon>
            </template>
          </button>
          <button
            type="button"
            :class="isMuted ? 'muted' : 'unmuted'"
            @click.prevent="toggleAudio()"
          >
            <template v-if="isMuted">
              <mic-off-icon size="1.0x" />
            </template>
            <template v-else>
              <mic-icon size="1.0x" />
            </template>
          </button>
        </div>
      </template>
      <template v-else>
        <div class="absolute bottom-0 md:bottom-1 left-0 right-0 text-white text-center">
          <button
            type="button"
            :class="'mr-3 ' + (isRemoteVideoEnabled ? 'videoOn' : 'videoOff')"
            @click.prevent="toggleRemoteVideo(participantId)"
          >
            <template v-if="isRemoteVideoEnabled">
              <video-icon size="1.0x"></video-icon>
            </template>
            <template v-else>
              <video-off-icon size="1.0x"></video-off-icon>
            </template>
          </button>
          <button
            type="button"
            :class="isRemoteAudioEnabled ? 'unmuted' : 'muted'"
            @click.prevent="toggleRemoteAudio(participantId)"
          >
            <template v-if="isRemoteAudioEnabled">
              <mic-icon size="1.0x" />
            </template>
            <template v-else>
              <mic-off-icon size="1.0x" />
            </template>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import {
  VideoOffIcon,
  VideoIcon,
  MicIcon,
  MicOffIcon
} from "@zhuowenli/vue-feather-icons";
import { ref } from "vue";
export default {
  components: {
    VideoOffIcon,
    VideoIcon,
    MicIcon,
    MicOffIcon
  },
  props: {
    participantId: {
      type: String,
      default: null
    },
    participantName: {
      type: String,
      default: null
    },
    isLocal: {
      type: Boolean,
      default: false
    },
    isMuted: {
      type: Boolean,
      default: false
    },
    isVideoEnabled: {
      type: Boolean,
      default: false
    },
    isRemoteAudioEnabled: {
      type: Boolean,
      default: false
    },
    isRemoteVideoEnabled: {
      type: Boolean,
      default: false
    },
    stream: {
      type: Object,
      required: false,
      default: null
    },
    videoClass: {
      type: String,
      default: ""
    },
    showLogo: {
      type: Boolean,
      default: false
    },
    bubble: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "toggle-audio",
    "toggle-video",
    "toggle-remote-audio",
    "toggle-remote-video"
  ],
  setup() {
    const video = ref(null);
    /*watch(()=> {
            if (props.stream && video) {
                video.current.srcObject = props.stream;
                video.current.onclick = () => false;
                video.current.ondblclick = toggleFullscreen;
                video.value = {
                    current: {
                        srcObject: props.stream,
                        onclick: () => false,
                        ondblclick: toggleFullscreen
                    }
                    srcObject: props.stream,
                        onclick: () => false,
                        ondblclick: toggleFullscreen
                }
            }
        }, []);*/

    /*const toggleFullscreen = e => {
            if (!document.fullscreenElement) {
                const fullScreenFunc = e.target.requestFullscreen ||
                    e.target.mozRequestFullScreen ||
                    e.target.webkitRequestFullscreen ||
                    e.target.msRequestFullscreen

                if (fullScreenFunc) {
                    fullScreenFunc();
                }

                e.target.controls = false;
            } else {
                document.exitFullscreen();
                e.target.controls = true;
            }
        };*/

    return { video };
  },
  methods: {
    toggleAudio() {
      this.$emit("toggle-audio");
    },
    toggleRemoteAudio(participantId) {
      this.$emit("toggle-remote-audio", participantId);
    },
    toggleVideo() {
      this.$emit("toggle-video");
    },
    toggleRemoteVideo(participantId) {
      this.$emit("toggle-remote-video", participantId);
    }
  }
};
</script>
<style scoped>
.bubble {
  height: 18vh;
  width: 18vh;
}

.horizontal .bubble {
  height: 15vh;
  width: 15vh;
}
</style>
