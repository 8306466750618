<template>
  <div class="flex items-center mb-2 mr-4">
    <input
      type="radio"
      class="h-4 w-4 text-gray-700 px-3 py-3 border rounded mr-2"
      :checked="modelValue === value"
      :value="value"
      v-bind="$attrs"
      @change="$emit('update:modelValue', $props.value)"
    />
    <label v-if="label">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    modelValue: {
      type: [String, Number],
      default: ""
    },
    value: {
      type: [String, Number],
      required: true
    }
  },
  emits: ["update:modelValue"]
};
</script>
