<template>
  <AlertMessage v-if="error" :type="danger">
    <template #message>
      <slot name="error">{{ error }}</slot>
    </template>
  </AlertMessage>
  <Suspense v-else>
    <template #default>
      <slot name="default"></slot>
    </template>
    <template #fallback>
      <slot name="fallback"></slot>
    </template>
  </Suspense>
</template>
<script>
import AlertMessage from "@/components/AlertMessage.vue";
import { ref, onErrorCaptured, Suspense } from "vue";
export default {
  components: {
    Suspense,
    AlertMessage
  },
  setup() {
    const error = ref(null);
    onErrorCaptured(e => {
      error.value = e;
      return true;
    });
    return { error };
  }
};
</script>
