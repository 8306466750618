<template>
  <section>
    <swiper
      @swiper="onSwiper"
      :pagination="{ clickable: true }"
      :autoplay="{ 'delay':5000 }"
      navigation
    >
      <NavBar class="absolute top-0 z-30 w-full" transparent />
      <swiper-slide class="h-screen w-full intro bg-center bg-cover" data-swiper-autoplay="8000">
        <div class="container mx-auto px-12">
          <div class="h-screen relative text-right text-white text-2xl md:text-3xl lg:text-5xl font-semibold py-32">
            <h1>{{ $t("home.intro.watch") }}</h1>
            <h2 class="text-gold-100 italic md:mt-4">
              {{ $t("home.intro.live") }}
            </h2>
            <h2 class="absolute right-0 bottom-20">
              {{ $t("home.intro.wherever") }}
            </h2>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide
        v-for="event in events"
        :key="event.id"
        :style="{ 'background-image': 'url(' + event.image + ')' }"
        class="bg-center bg-cover"
      >
        <div class="container mx-auto px-12">
          <div class="flex flex-col justify-end items-end h-screen py-24">
            <div class="text-white">
              <h2 class="text-4xl font-medium">{{ event.title }}</h2>
              <div class="mb-5 text-lg sm:text-2xl">
                <span class="text-gold-100 font-bold">
                  {{ format(event.date, "d") }}
                </span>
                {{ format(event.date, "MMMM y - HH:mm", $i18n.locale) }}
              </div>
              <div class="flex">
                <RouterLink
                  :to="{ name: 'Concert', params: { slug: event.slug } }"
                  class="bg-violet-100 hover:bg-violet-200 text-white text-2xl font-medium px-4 py-3 rounded-lg mr-2"
                >
                  {{ $t("home.featured.showTickets") }}
                </RouterLink>
                <ShareLink
                  :key="event.id"
                  :key-id="event.id"
                  :url="
                    $root.getFullRouteUrl({
                      name: 'Concert',
                      params: { slug: event.slug }
                    })
                  "
                  :title="event.title"
                  description=""
                  quote=""
                  hashtags=""
                  button
                  icon-size="1.8x"
                >
                </ShareLink>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </section>

  <section class="upcoming bg-gray-100">
    <div class="container mx-auto px-4">
      <h2 class="font-semibold text-2xl md:text-3xl lg:text-5xl pt-10 sm:pt-20">
        {{ $t("home.upcoming.title") }}
      </h2>
      <div class="bg-violet-100 h-1 w-12 my-4"></div>

      <div class="grid grid-cols-1 sm:grid-cols-3 gap-8 pt-10 pb-16">
        <div
          v-for="event in events"
          :key="event.id"
          class="rounded-xl overflow-hidden shadow-lg"
        >
          <RouterLink :to="{ name: 'Concert', params: { slug: event.slug } }">
            <img class="w-full" :src="event.image" />
          </RouterLink>
          <div class="p-4">
            <div class="flex justify-between">
              <div class="mb-2 text-gray-900 text-lg lg:text-3xl">
                <RouterLink
                  :to="{ name: 'Concert', params: { slug: event.slug } }"
                >
                  {{ event.title }}
                </RouterLink>
              </div>
              <div class="self-start">
                <span
                  v-if="event.new"
                  class="inline-block bg-gold-200 rounded-full px-2 py-1 text-sm font-semibold text-white"
                >
                  {{ $t("home.upcoming.new") }}
                </span>
              </div>
            </div>
            <div class="flex justify-between items-center">
              <p class="text-gray-700 uppercase lg:text-2xl">
                <span class="font-bold text-violet-200">
                  {{ format(event.date, "d") }}
                </span>
                {{ format(event.date, "MMMM y", $i18n.locale) }}
              </p>
              <ShareLink
                :key="event.id"
                :key-id="event.id"
                :url="
                  $root.getFullRouteUrl({
                    name: 'Concert',
                    params: { slug: event.slug }
                  })
                "
                :title="event.title"
                description=""
                quote=""
                hashtags=""
              >
              </ShareLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="with-friends bg-gray-100">
    <div class="container mx-auto px-4">
      <div class="py-20">
        <div
          class="flex flex-col md:flex-row space-y-8 md:space-x-4 md:space-y-0"
        >
          <div class="self-center flex-grow">
            <h2 class="font-semibold text-2xl md:text-3xl lg:text-4xl mb-5">
              {{ $t("home.withFriends.title") }}
              <span class="text-gold-100">{{
                $t("home.withFriends.titleGold")
              }}</span>
            </h2>
            <p class="leading-6 md:text-xl md:leading-6">
              {{ $t("home.withFriends.text") }}
            </p>
          </div>
          <div class="self-center">
            <img
              src="../assets/img/watch-together-macbook.png"
              class="md:max-w-sm lg:max-w-xl"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="artists bg-gray-100">
    <div class="container mx-auto px-4">
      <div class="py-20 text-center">
        <h2 class="text-xl font-semibold my-2">
          {{ $t("home.artists.title") }}
        </h2>
        <p class="mb-10">{{ $t("home.artists.text") }}</p>
        <Button type="button" @click="toggleModal">
          {{ $t("home.artists.contact") }}
        </Button>
      </div>
    </div>
  </section>

  <Modal v-if="showModal" :show-header="false" width="lg" @close="toggleModal">
    <Contact @closeModal="toggleModal" />
  </Modal>

  <Footer />
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import Button from "@/components/Button.vue";
import Contact from "@/components/Contact.vue";
import Modal from "@/components/Modal.vue";
import ShareLink from "@/components/ShareLink.vue";
import useEvents from "@/composables/use-events";
import { ref, onMounted } from "vue";
import format from "@/plugins/date-fns";
import SwiperCore, { Autoplay, Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/vue";

SwiperCore.use([Autoplay, Navigation, Pagination, A11y]);
import "swiper/swiper-bundle.css";

export default {
  name: "Home",
  components: {
    NavBar,
    Footer,
    Contact,
    Modal,
    Button,
    ShareLink,
    Swiper,
    SwiperSlide,
  },

  setup() {
    const showModal = ref(false);
    const { getEvents, events } = useEvents();
    const onSwiper = (swiper) => {
      window.swiper = swiper;
    };
//    const promoted = computed(() => {
//      for (let i = 0; i < events.value.length; i++) {
//        if (events.value[i].promoted) {
//          return events.value[i];
//        }
//      }
//      return null;
//    });
//    const upcomingEvents = computed(() => {
//      let upcoming = [];
//      for (let i = 0; i < events.value.length; i++) {
//        if (!events.value[i].promoted) {
//          upcoming.push(events.value[i]);
//        }
//      }
//      return upcoming;
//    });
    onMounted(() => {
      getEvents();
    });
    function toggleModal() {
      showModal.value = !showModal.value;
    }
    return { format, onSwiper, showModal, toggleModal, events };
  }
};
</script>

<style scoped>
.intro {
  background-image: url("../assets/img/section-bg/wt-live.jpg");
}
.featured {
  background-image: url("../assets/img/section-bg/bw-01.jpg");
}
/deep/ .swiper-pagination-bullet-active {
  background: #8D3E63;
}
/deep/ .swiper-button-prev,
/deep/ .swiper-button-next {
  color: #8D3E63;
}
</style>
