<script>
import { getComponent } from "./ComponentTypes";
import { h } from "vue";
export default {
  functional: true,
  props: {
    ticket: {
      type: Object,
      default: null
    }
  },
  render() {
    if (!this.ticket.id) {
      return null;
    }
    return h(getComponent("TICKET"), {
      ticket: this.ticket
    });
  }
};
</script>
