<template>
  <header>
    <div
      class="h-72 concert-header bg-cover bg-center"
      :style="{ 'background-image': 'url(' + event.image + ')' }"
    >
      <NavBar transparent />
    </div>
    <div class="bg-gray-100">
      <div class="container mx-auto px-4 lg:max-w-4xl">
        <div class="flex space-x-4">
          <div
            v-if="event.date"
            class="bg-white rounded-xl shadow-lg uppercase text-sm text-center px-5 py-2 z-10 text-lg sm:text-xl"
          >
            <h2 class="font-bold text-violet-200">
              {{ format(event.date, "d") }}
            </h2>
            <p>{{ format(event.date, "MMMM", $i18n.locale) }}</p>
            <p>{{ format(event.date, "y") }}</p>
            <p>-</p>
            <p>{{ format(event.date, "HH:mm") }}</p>
          </div>
          <div class="flex-grow z-10">
            <h1
              class="text-4xl sm:text-6xl font-semibold text-white uppercase pt-4"
            >
              {{ event.title }}
            </h1>
          </div>
          <div class="self-center z-10">
            <ShareLink
              :key="event.slug"
              :url="
                $root.getFullRouteUrl({
                  name: 'Concert',
                  params: { slug: event.slug }
                })
              "
              :title="event.title"
              description=""
              :key-id="event.id"
              button
              round
              shadow
            />
          </div>
        </div>
      </div>
    </div>
  </header>
  <section class="info bg-gray-100 text-gray-900">
    <div class="container mx-auto px-4 pb-24">
      <div class="flex flex-col md:flex-row gap-6 items-center justify-between">
        <div v-if="event.social" class="md:w-16">
          <div class="text-center text-xl font-bold sm:transform md:-rotate-90 md:translate-y-20">
            {{ event.social.hashtag }}
          </div>
        </div>
        <div class="flex-grow max-w-4xl">
          <div class="flex flex-col md:flex-row gap-6 items-center">
            <div class="md:w-1/2">
              <div v-html="event.description"></div>
            </div>
            <div class="w-full md:w-1/2">
              <iframe
                v-if="event.video"
                :src="'//player.vimeo.com/video/' + event.video"
                class="w-full h-96"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
        <div v-if="event.social" class="flex flex-row md:flex-col gap-3 md:w-16">
            <a v-for="channel in event.social.channels" :key="channel.channel" :href="channel.url" class="" target="_blank">
              <InstagramIcon v-if="channel.channel === 'instagram'" size="2.0x"  />
              <FacebookIcon  v-if="channel.channel === 'facebook'" size="2.0x" />
              <TwitterIcon   v-if="channel.channel === 'twitter'" size="2.0x" />
              <YoutubeIcon   v-if="channel.channel === 'youtube'" size="2.0x" />
            </a>
        </div>
      </div>
    </div>
  </section>
  <section class="tickets">
    <div class="container mx-auto px-4 lg:max-w-4xl mt-12 pb-1">
      <h2 class="text-4xl font-semibold pt-8">
        {{ $t("concertInfo.tickets.title") }}
      </h2>

      <TicketCard
        v-for="offer in event.offers"
        :key="offer.id"
        :offer="offer"
        :display-date="
          format(event.date, 'EEEE d MMMM y . HH:mm', $i18n.locale)
        "
        @add-to-cart="updateCart"
        @remove-from-cart="updateCart"
      />
    </div>
    <div class="container mx-auto mb-10 sm:px-4 sm:pb-10 lg:max-w-4xl">
      <div
        class="bg-gray-200 sm:rounded-2xl px-6 py-6 md:py-4 flex justify-between items-center"
      >
        <div>
          {{ $t("concertInfo.tickets.summary", cart.items.length) }}
          <span v-if="cart.items.length" class="text-gold-300"
            >{{ cart.total }} €</span
          >
        </div>
        <Button
          type="button"
          :disabled="cart.items.length === 0 ? true : false"
          @click="toggleModal"
        >
          {{ $t("concertInfo.tickets.reserve") }}
        </Button>
      </div>
    </div>
  </section>

  <Modal
    v-if="showModal"
    title="Buy tickets"
    :show-header="false"
    width="lg"
    @close="toggleModal"
  >
    <Checkout :cart="cart" @closeModal="toggleModal" />
  </Modal>

  <Footer />
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import Button from "@/components/Button.vue";
import Checkout from "@/components/Checkout.vue";
import ShareLink from "@/components/ShareLink.vue";
import TicketCard from "@/components/TicketCard.vue";
import useEvents from "@/composables/use-events";
import Modal from "@/components/Modal.vue";
import format from "@/plugins/date-fns";
import { FacebookIcon, InstagramIcon, TwitterIcon, YoutubeIcon } from "@zhuowenli/vue-feather-icons";
import { onMounted, ref, reactive } from "vue";
export default {
  components: {
    NavBar,
    Button,
    Checkout,
    Footer,
    TicketCard,
    ShareLink,
    Modal,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    YoutubeIcon
  },
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { getEventBySlug, event } = useEvents();
    const showModal = ref(false);
    const cart = reactive({
      items: [],
      total: 0
    });

    function updateCart(id, remove = false) {
      if (remove) {
        cart.items.splice(cart.items.indexOf(id), 1);
        cart.total -= event.value.offers.find(x => x.id === id).price;
      } else {
        cart.items.push(id);
        cart.total += event.value.offers.find(x => x.id === id).price;
      }
    }

    function toggleModal() {
      showModal.value = !showModal.value;
    }
    onMounted(() => getEventBySlug(props.slug));
    return { event, format, cart, updateCart, showModal, toggleModal };
  }
};
</script>

<style scoped>
.concert-header {
  -webkit-mask: url("../assets/img/concert-mask.svg");
  -webkit-mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: bottom;
}

.info {
  -webkit-mask: url("../assets/img/concert-mask-flip.svg");
  -webkit-mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: bottom;
}

header .container {
  margin-top: -100px;
}

</style>
