import { ref, watch } from "vue";
import WT from "@sscale/wtsdk";

export default function useWatchTogether() {
  const isMuted = ref(false);
  const isVideoEnabled = ref(false);
  const isRemoteVideoEnabled = ref(true);
  const isRemoteAudioEnabled = ref(true);
  const isMainViewerMode = ref(false);
  const participants = ref([]);
  const participantsRef = ref(participants);

  watch(() => {
    participantsRef.value = participants.value;
  }, [participants]);

  watch(() => {
    WT.SessionListeners.onConnected(() => {
      // Calls when new streams added
      WT.SessionListeners.onStreamCreated(stream => {
        const { streams, participantId, local, participantName } = stream;
        console.log("============================");
        console.log(stream);
        console.log("============================");
        setParticipants([
          ...participantsRef.value,
          { stream: streams[0], participantName, participantId, isLocal: local }
        ]);
      });

      WT.ParticipantListeners.onParticipantLeft(({ participant }) => {
        setParticipants(
          participantsRef.value.filter(p => p.participantId !== participant.id)
        );
      });

      WT.ParticipantListeners.onParticipantMediaStreamChanged(
        mediaStreamChanged => {
          console.log(mediaStreamChanged);
        }
      );

      WT.ErrorListeners.onSessionError(event => {
        // Error handling
        if (event.error.code === 206) {
          // Handle full room error
        }
      });
    });
  }, []);

  function setParticipants(args) {
    participants.value = args;
    participantsRef.value = args;
  }

  function setIsVideoEnabled(args) {
    isVideoEnabled.value = args;
  }

  function setIsRemoteVideoEnabled(args) {
    isRemoteVideoEnabled.value = args;
  }

  function setIsRemoteAudioEnabled(args) {
    isRemoteAudioEnabled.value = args;
  }

  function setIsMuted(args) {
    isMuted.value = args;
  }

  function toggleAudio() {
    if (WT.Participant.isAudioEnabled()) {
      WT.Participant.disableAudio();
      setIsMuted(true);
    } else {
      WT.Participant.enableAudio();
      setIsMuted(false);
    }
  }

  function toggleVideo() {
    if (WT.Participant.isVideoEnabled()) {
      WT.Participant.disableVideo();
      setIsVideoEnabled(true);
    } else {
      WT.Participant.enableVideo();
      setIsVideoEnabled(false);
    }
  }

  function toggleRemoteAudio(participantId) {
    WT.Participant.toggleRemoteAudio(participantId);
    setIsRemoteAudioEnabled( WT.Participant.isRemoteAudioEnabled(participantId) );
  }

  function toggleRemoteVideo(participantId) {
    WT.Participant.toggleRemoteVideo(participantId);
    setIsRemoteVideoEnabled( WT.Participant.isRemoteVideoEnabled(participantId) );
  }

  function shareScreen() {
    WT.Participant.startScreenSharing("test-video");
    setParticipants(participantsRef.value);
  }

  function startConnecting(sessionToken, url, session, displayName) {
    const connect = WT.Session.connect; //isViewerMode ? WT.Session.connectAsViewer : WT.Session.connect;
    let wsUrl = "wss://" + url;
    const settings = {
      sessionToken,
      wsUrl,
      sessionId: session
    };

    const userInfo = {
      displayed_name: displayName
    };

    connect(settings, userInfo);
  }

  return {
    toggleAudio,
    toggleVideo,
    toggleRemoteAudio,
    toggleRemoteVideo,
    shareScreen,
    startConnecting,
    participantsRef,
    isMuted,
    isVideoEnabled,
    isRemoteAudioEnabled,
    isRemoteVideoEnabled,
    isMainViewerMode
  };
}
