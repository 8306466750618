import gql from "graphql-tag";

export const CREATE_ORDER_MUTATION = gql`
  mutation createOrder(
    $firstname: String!
    $lastname: String!
    $email: String!
    $offers: [String!]!
  ) {
    createOrder(
      input: {
        firstname: $firstname
        lastname: $lastname
        email: $email
        offers: $offers
      }
    ) {
      id
      action
      form {
        pbx_site
        pbx_rang
        pbx_identifiant
        pbx_total
        pbx_devise
        pbx_cmd
        pbx_porteur
        pbx_retour
        pbx_hash
        pbx_effectue
        pbx_annule
        pbx_refuse
        pbx_attente
        pbx_repondre_a
        pbx_time
        pbx_hmac
      }
    }
  }
`;
