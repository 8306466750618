<template>
  <div
    class="md:grid md:grid-cols-12"
    :class="!showChat ? 'container mx-auto' : ''"
  >
    <div :class="showChat ? 'md:col-span-8 lg:col-span-9' : 'md:col-span-12'">
      <div class="embed-container h-72 sm:h-96">
        <iframe
          :src="'//vimeo.com/event/' + getVideoId(ticket.video_id) + '/embed'"
          width="100%"
          height="100%"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div
      v-if="showChat"
      class="p-5 md:col-span-4 lg:col-span-3 md:self-center"
      :class="validated ? 'chat-container' : ''"
    >
      <NicknameInput v-if="!validated" @validate="validate"></NicknameInput>
      <livelike-chat
        v-else
        :roomid="ticket.chat.room_id"
      ></livelike-chat>
    </div>
  </div>
</template>
<script>
import useTickets from "@/composables/use-tickets";
import LiveLike from "@livelike/engagementsdk";
import NicknameInput from "@/components/NicknameInput.vue";
import { ref } from "vue";
export default {
  components: {
    NicknameInput
  },
  props: {
    ticket: {
      type: Object,
      default: null
    },
    showChat: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const { getVideoId } = useTickets();
    const validated = ref(false);

    function validate(value, name) {
      validated.value = value;
      LiveLike.init({
        clientId: props.ticket.chat.client_id,
        nickName: name
      });
    }

    return { getVideoId, validated, validate };
  }
};
</script>
<style scoped>
.chat-container {
  height: calc(100vh - 24rem);
}
.embed-container {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  background: black;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
iframe form {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
@media screen and (min-width: 640px) {
  .chat-container {
    height: calc(100vh - 30rem);
  }
}
@media screen and (min-width: 768px) {
  .embed-container,
  .chat-container {
    height: calc(100vh - 93px);
  }
}
</style>
