<template>
  <VIP v-if="ticket.type === 'VIP'" :ticket="ticket" />
  <Room v-else-if="ticket.type === 'ROOM'" :ticket="ticket" />
  <Standard v-else :ticket="ticket" />
</template>
<script>
import Standard from "@/components/tickets/Standard.vue";
import Room from "@/components/tickets/Room.vue";
import VIP from "@/components/tickets/VIP.vue";
export default {
  components: {
    Standard,
    Room,
    VIP
  },
  props: {
    ticket: {
      type: Object,
      default: null
    }
  }
};
</script>
