<template>
  <teleport to="#modal-wrapper">
    <Modal2
      v-if="$root.modalOpen[uniqueId]"
      :key-id="uniqueId"
      :title="$t('share.modalTitle')"
    >
      <SocialShare
        v-for="network in networks"
        :key="uniqueId + '-' + network"
        :network="network"
        :url="url"
        :key-id="uniqueId"
        :title="title"
        :description="description"
        :quote="quote"
        :hashtags="hashtags"
        :twitter-user="twitterUser"
      >
        <span v-if="network === 'facebook'"
          ><FacebookIcon class="float-left mr-2" />{{
            $t("share.shareOn")
          }}
          Facebook</span
        >
        <span v-if="network === 'twitter'"
          ><TwitterIcon class="float-left mr-2" />
          {{ $t("share.shareOn") }} Twitter</span
        >
        <span v-if="network === 'linkedin'"
          ><LinkedinIcon class="float-left mr-2" />{{
            $t("share.shareOn")
          }}
          LinkedIn</span
        >
      </SocialShare>
    </Modal2>
  </teleport>
  <Button
    v-if="button"
    type="button"
    color="white"
    class="elementClass"
    :round="round"
    :shadow="shadow"
    @click="showModal(uniqueId)"
    ><Share2Icon :size="iconSize"
  /></Button>
  <a v-else href="#" :class="elementClass" @click="showModal(uniqueId)"
    ><Share2Icon :size="iconSize"
  /></a>
</template>
<script>
import Button from "@/components/Button.vue";
import Modal2 from "@/components/Modal2.vue";
import SocialShare from "@/components/SocialShare";
import {
  Share2Icon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon
} from "@zhuowenli/vue-feather-icons";
export default {
  components: {
    Button,
    Share2Icon,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    Modal2,
    SocialShare
  },
  props: {
    networks: {
      type: Array,
      default: function() {
        return ["facebook", "twitter", "linkedin"];
      }
    },

    url: {
      type: String,
      required: true
    },

    keyId: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    description: {
      type: String,
      default: ""
    },

    quote: {
      type: String,
      default: ""
    },

    hashtags: {
      type: String,
      default: ""
    },

    twitterUser: {
      type: String,
      default: ""
    },

    round: {
      type: Boolean,
      default: false
    },

    shadow: {
      type: Boolean,
      default: false
    },

    button: {
      type: Boolean,
      default: false
    },

    iconSize: {
      type: String,
      default: "1.0x"
    },

    elementClass: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      uniqueId: null
    };
  },
  created() {
    this.uniqueId = this.keyId;
  },
  methods: {
    closeModal() {},

    showModal(id) {
      this.$root.modalOpen[id] = true;
    }
  }
};
</script>
