import gql from "graphql-tag";

export const ALL_TICKETS_QUERY = gql`
  query getTickets {
    getTickets {
      id
      token
      type
      video_id
      main_screen
      room
      sceenic_token {
        url
        token
        room
        fullToken
      }
    }
  }
`;

export const TICKET_QUERY = gql`
  query getTicket($id: String!) {
    getTicket(id: $id) {
      id
      token
      type
      video_id
      main_screen
      room
      sceenic_token {
        url
        token
        room
        fullToken
      }
      chat {
        client_id
        room_id
      }
      evt {
        id
        title
        slug
        description
      }
    }
  }
`;
