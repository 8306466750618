<template>
  <div class="flex flex-col min-h-screen" :class="$route.meta.section">
    <router-view />
  </div>
  <CookieLaw
    :message="$t('cookies.message')"
    :button-link="{ name: 'Cookie Policy' }"
    :button-link-text="$t('cookies.buttonLinkText')"
    :button-text="$t('cookies.buttonText')"
  />
</template>
<script>
import CookieLaw from "@/components/CookieLaw.vue";
import { ref } from "vue";
export default {
  name: "App",
  components: {
    CookieLaw
  },
  setup() {
    const modalOpen = ref({});
    const toggleModalState = id => {
      modalOpen.value[id] = false;
    };
    return {
      modalOpen,
      toggleModalState
    };
  },
  methods: {
    getFullRouteUrl(params) {
      return window.location.origin + this.$router.resolve(params).href;
    }
  }
};
</script>
