<template>
  <header class="bg-gray-800">
    <NavBar transparent />
  </header>

  <div class="container mx-auto p-4 my-10 mb-auto">
    <h1 class="text-xl font-bold my-5">{{ $t("not-found.title") }}</h1>
    <p class="my-5">{{ $t("not-found.copy") }}</p>
    <router-link :to="{ name: 'Home' }" class="my-5">
      <Button>{{ $t("not-found.link") }}</Button>
    </router-link>
  </div>

  <Footer />
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import Button from "@/components/Button.vue";

export default {
  components: {
    NavBar,
    Footer,
    Button
  },
  props: {
    section: {
      type: String,
      required: true
    }
  }
};
</script>
