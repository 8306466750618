import { format, parseISO } from "date-fns";
import { enGB, fr } from "date-fns/locale";

const locales = { en: enGB, fr: fr };

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export default function(dateStr, formatStr = "PP", localeString = "en") {
  return format(parseISO(dateStr), formatStr, {
    locale: locales[localeString] // or global.__localeId__
  });
}
