<template>
  <h2 class="text-center font-semibold my-12">{{ $t("contact.title") }}</h2>

  <AlertMessage v-if="error" type="danger" class="mb-10">
    <template #message>{{ error }}</template>
  </AlertMessage>

  <form v-if="!messageSent" @submit.prevent="submitContactForm()">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-6">
      <div>
        <BaseInput
          v-model="message.firstName"
          type="text"
          :label="$t('contact.form.label.firstName')"
          :class="{ 'border-red-500': v.message.firstName.$error }"
          @blur="v.message.firstName.$touch()"
        />

        <div
          v-if="v.message.firstName.$error"
          class="text-xs text-red-600 mt-1"
        >
          <p
            v-for="error in v.message.firstName.$errors"
            :key="error.$property"
          >
            {{ error.$message }}
          </p>
        </div>
      </div>

      <div>
        <BaseInput
          v-model="message.lastName"
          type="text"
          :label="$t('contact.form.label.lastName')"
          :class="{ 'border-red-500': v.message.lastName.$error }"
          @blur="v.message.lastName.$touch()"
        />

        <div v-if="v.message.lastName.$error" class="text-xs text-red-600 mt-1">
          <p v-for="error in v.message.lastName.$errors" :key="error.$property">
            {{ error.$message }}
          </p>
        </div>
      </div>

      <div class="md:col-span-2">
        <BaseInput
          v-model="message.email"
          type="email"
          :label="$t('contact.form.label.email')"
          :class="{ 'border-red-500': v.message.email.$error }"
          @blur="v.message.email.$touch()"
        />

        <div v-if="v.message.email.$error" class="text-xs text-red-600 mt-1">
          <p v-for="error in v.message.email.$errors" :key="error.$property">
            {{ error.$message }}
          </p>
        </div>
      </div>

      <div class="md:col-span-2">
        <BaseTextarea
          v-model="message.message"
          :label="$t('contact.form.label.message')"
          :class="{ 'border-red-500': v.message.message.$error }"
          @blur="v.message.message.$touch()"
        />

        <div v-if="v.message.message.$error" class="text-xs text-red-600 mt-1">
          <p v-for="error in v.message.message.$errors" :key="error.$property">
            {{ error.$message }}
          </p>
        </div>
      </div>

      <div class="md:col-span-2 text-center">
        <Button type="submit" size="lg" :disabled="v.$anyerror">
          {{ $t("contact.form.submit") }}
        </Button>
      </div>
    </div>
  </form>

  <div v-else>
    <AlertMessage type="success" class="mb-10">
      <template #message>{{ $t("contact.success") }}</template>
    </AlertMessage>

    <div class="text-center">
      <p>{{ $t("contact.messageSent") }}</p>
      <Button type="button" @click="$emit('close-modal')">{{
        $t("contact.closeModal")
      }}</Button>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import AlertMessage from "@/components/AlertMessage.vue";
import Button from "@/components/Button.vue";
import useMessages from "@/composables/use-messages";

export default {
  name: "Contact",
  components: {
    AlertMessage,
    Button
  },
  emits: ["close-modal"],
  setup() {
    const { sendMessage, messageSent, error } = useMessages();
    const message = reactive({
      firstName: "",
      lastName: "",
      email: "",
      message: ""
    });
    const validationArgs = {
      message: {
        firstName: {
          required
        },
        lastName: {
          required
        },
        email: {
          required,
          email
        },
        message: {
          required
        },
        required
      }
    };
    const v = useVuelidate(validationArgs, { message });

    function submitContactForm() {
      v.value.$touch();
      if (!v.value.$invalid) {
        sendMessage(
          message.firstName,
          message.lastName,
          message.email,
          message.message
        );
      }
    }

    return { message, messageSent, error, v, submitContactForm };
  }
};
</script>
