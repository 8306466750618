/**
 * We use shorter names to reduce the final bundle size
 *
 * Properties:
 * @u = url
 * @t = title
 * @d = description
 * @q = quote
 * @h = hashtags
 */
export default {
  facebook:
    "https://www.facebook.com/sharer/sharer.php?u=@u&title=@t&description=@d&quote=@q&hashtag=@h",
  linkedin: "https://www.linkedin.com/sharing/share-offsite/?url=@u",
  twitter: "https://twitter.com/intent/tweet?text=@t&url=@u&hashtags=@h@tu"
};
