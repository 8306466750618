<template>
  <div class="container mx-auto px-5 pt-16 sm:pt-32 lg:pt-24 xl:pt-16">
    <div
      v-if="activeMenu == 'video_show_horizontal'"
      class="flex items-center justify-center bg-gray-800 p-2 horizontal"
    >
      <div
        v-for="participant in viewerParticipants"
        :key="participant.participantId"
        class=""
      >
        <Video
          :participant-id="participant.participantId"
          :participant-name="participant.participantName"
          :is-muted="isMuted"
          :is-video-enabled="isVideoEnabled"
          :is-remote-audio-enabled="isRemoteAudioEnabled"
          :is-remote-video-enabled="isRemoteVideoEnabled"
          :stream="participant.stream"
          :is-local="participant.isLocal"
          video-class="object-cover object-center md:border-2 md:border-gray-700"
          bubble
          @toggleAudio="toggleAudio"
          @toggleVideo="toggleVideo"
          @shareScreen="shareScreen"
        />
      </div>
      <Button
        type="button"
        :size="sm"
        :color="base"
        :fullwidth="false"
        float="right1"
        @click="showVertical"
      >
        {{ $t("live.button.vertical") }}
      </Button>
    </div>
    <div class="md:grid md:grid-cols-12 md:gap-6 md:align clear-both">
      <div :class="videoClassComputed">
        <div
          v-for="participant in mainParticipant"
          :key="'main-' + participant.participantId"
          class="main-video embed-container"
        >
          <Video
            :participant-id="participant.participantId"
            :participant-name="ticket.evt.title"
            :is-muted="isMuted"
            :is-video-enabled="isVideoEnabled"
            :is-remote-audio-enabled="isRemoteAudioEnabled"
            :is-remote-video-enabled="isRemoteVideoEnabled"
            :stream="participant.stream"
            :is-local="participant.isLocal"
            video-class="w-full max-h-screen-3/4 object-cover object-center md:border-2 md:border-gray-700"
            show-logo
            @toggleAudio="toggleAudio"
            @toggleVideo="toggleVideo"
            @toggle-remote-audio="toggleRemoteAudio"
            @toggle-remote-video="toggleRemoteVideo"
            @shareScreen="shareScreen"
          />
        </div>
      </div>
      <div
        v-if="activeMenu == 'video_show_vertical'"
        class="md:col-span-3 justify-self-center text-center"
      >
        <NicknameInput v-if="!isValidated" @validate="validate"></NicknameInput>
        <template v-if="isValidated">
          <Button
            type="button"
            :size="sm"
            :color="base"
            class="mb-3 hidden md:inline-block"
            @click="showHorizontal"
            >{{ $t("live.button.horizontal") }}</Button
          >
          <div
            v-for="participant in viewerParticipants"
            :key="participant.participantId"
            class="mb-2"
          >
            <Video
              :participant-id="participant.participantId"
              :participant-name="participant.participantName"
              :is-muted="isMuted"
              :is-video-enabled="isVideoEnabled"
              :is-remote-audio-enabled="isRemoteAudioEnabled"
              :is-remote-video-enabled="isRemoteVideoEnabled"
              :is-local="participant.isLocal"
              :stream="participant.stream"
              video-class="object-cover object-center md:border-2 md:border-gray-700"
              bubble
              @toggle-audio="toggleAudio"
              @toggle-video="toggleVideo"
              @toggle-remote-audio="toggleRemoteAudio"
              @toggle-remote-video="toggleRemoteVideo"
              @shareScreen="shareScreen"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@/components/Button.vue";
import Video from "@/components/Video.vue";
import NicknameInput from "@/components/NicknameInput.vue";
import useTickets from "@/composables/use-tickets";
import useWatchTogether from "@/composables/use-watch-together";
export default {
  components: {
    Video,
    NicknameInput,
    Button
  },
  props: {
    ticket: {
      type: Object,
      default: null
    }
  },
  setup() {
    const {
      toggleAudio,
      toggleVideo,
      toggleRemoteAudio,
      toggleRemoteVideo,
      shareScreen,
      startConnecting,
      participantsRef,
      isMuted,
      isVideoEnabled,
      isRemoteAudioEnabled,
      isRemoteVideoEnabled,
      isMainViewerMode
    } = useWatchTogether();

    const { getVideoId } = useTickets();

    return {
      toggleAudio,
      toggleVideo,
      toggleRemoteAudio,
      toggleRemoteVideo,
      shareScreen,
      startConnecting,
      participantsRef,
      isMuted,
      isVideoEnabled,
      isRemoteAudioEnabled,
      isRemoteVideoEnabled,
      isMainViewerMode,
      getVideoId
    };
  },
  data() {
    return {
      activeMenu: "video_show_vertical",
      videoClass: "md:col-span-9",
      validated: false
    };
  },
  computed: {
    videoClassComputed() {
      return this.videoClass;
    },
    isValidated() {
      return this.validated;
    },
    viewerParticipants() {
      return this.participantsRef.filter(p => p.participantName !== "admin");
    },
    mainParticipant() {
      return this.participantsRef.filter(p => p.participantName === "admin");
    }
  },
  created() {
    if (this.ticket.main_screen) {
      this.validated = true;
      this.startConnecting(
        this.ticket.sceenic_token.fullToken,
        this.ticket.sceenic_token.url,
        this.ticket.sceenic_token.room,
        "admin"
      );
    }
  },
  methods: {
    toggle(menu) {
      this.activeMenu = menu;
      switch (menu) {
        case "video_show_vertical":
          this.videoClass = "md:col-span-9";
          break;
        case "video_show_horizontal":
          this.videoClass = "md:col-span-12";
          break;
        default:
          this.activeMenu = "none";
          this.videoClass = "md:col-span-12 text-center";
      }
    },
    showVertical() {
      this.toggle("video_show_vertical");
    },
    showHorizontal() {
      this.toggle("video_show_horizontal");
    },
    validate(value, name) {
      if (name === "admin") {
        alert("Unable to use that name.");
      } else {
        this.validated = value;
        this.startConnecting(
          this.ticket.sceenic_token.fullToken,
          this.ticket.sceenic_token.url,
          this.ticket.sceenic_token.room,
          name
        );
      }
    }
  }
};
</script>
<style scoped>
.embed-container {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  background: black;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
iframe form {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
</style>
