import gql from "graphql-tag";

export const SEND_MESSAGE_MUTATION = gql`
  mutation sendMessage(
    $firstName: String!
    $lastName: String!
    $email: String!
    $message: String!
  ) {
    sendMessage(
      firstName: $firstName
      lastName: $lastName
      email: $email
      message: $message
    )
  }
`;
