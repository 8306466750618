<template>
  <header class="bg-gray-800">
    <NavBar transparent />
  </header>

  <div class="container mx-auto p-4 mb-auto">
    <section>
      <h1 class="text-xl font-bold py-5">
        {{ $t("terms." + section + ".title") }}
      </h1>
      <div class="terms" v-html="$t('terms.' + section + '.terms')"></div>
    </section>
  </div>

  <Footer />
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    NavBar,
    Footer
  },
  props: {
    section: {
      type: String,
      required: true
    }
  }
};
</script>
<style>
.terms p {
  margin-bottom: 1.25rem;
}
</style>
