<template>
  <div>
    <div
      v-if="showSceenic && sceenicOrientation == 'horizontal'"
      class="flex items-center justify-center bg-gray-800 p-2 horizontal"
    >
      <div
        v-for="participant in participantsRef"
        :key="participant.participantId"
      >
        <Video
          :participant-id="participant.participantId"
          :participant-name="participant.participantName"
          :is-muted="isMuted"
          :is-video-enabled="isVideoEnabled"
          :is-remote-audio-enabled="isRemoteAudioEnabled"
          :is-remote-video-enabled="isRemoteVideoEnabled"
          :stream="participant.stream"
          :is-local="participant.isLocal"
          video-class="object-cover object-center border border-grey-800"
          bubble
          @toggleAudio="toggleAudio"
          @toggleVideo="toggleVideo"
          @toggle-remote-audio="toggleRemoteAudio"
          @toggle-remote-video="toggleRemoteVideo"
          @shareScreen="shareScreen"
        />
      </div>
      <div class="p-4">
        <Button
          type="button"
          class="whitespace-nowrap"
          :size="sm"
          :color="base"
          :fullwidth="false"
          @click="setSceenicOrientation('vertical')"
        >
          {{ $t("live.button.vertical") }}
        </Button>
      </div>
    </div>
    <div
      class="md:grid md:grid-cols-12"
      :class="!showChat && !showSceenic ? 'container mx-auto' : ''"
    >
      <div :class="videoClass">
        <div
          class="embed-container h-72 sm:h-96"
          :class="showSceenic ? sceenicOrientation : ''"
        >
          <div class="vimeo-wrapper">
            <iframe
              :src="
                '//vimeo.com/event/' + getVideoId(ticket.video_id) + '/embed'
              "
              width="100%"
              height="100%"
              frameborder="0"
              class="h-56"
              allow="autoplay; fullscreen; picture-in-picture"
            ></iframe>
          </div>
        </div>
      </div>
      <div class="bg-white leading-0 md:hidden">
        <a
          class="inline-block w-1/2 px-auto py-2"
          :class="showChat ? 'bg-gray-800 text-white' : ''"
          href="#"
          @click="$emit('toggle-chat')"
        >
          <message-square-icon
            size="1.8x"
            class="mx-auto"
          ></message-square-icon>
        </a>
        <a
          v-if="ticket.type === 'ROOM'"
          class="inline-block w-1/2 px-auto py-2"
          :class="showSceenic ? 'bg-gray-800 text-white' : ''"
          href="#"
          @click="$emit('toggle-sceenic')"
        >
          <video-icon size="1.8x" class="mx-auto"></video-icon>
        </a>
      </div>
      <div
        v-if="showChat"
        class="p-5 md:col-span-4 lg:col-span-3 md:self-center"
        :class="validated ? 'chat-container' : ''"
      >
        <NicknameInput v-if="!validated" @validate="validate"></NicknameInput>
        <livelike-chat v-else :roomid="ticket.chat.room_id"></livelike-chat>
      </div>
      <div
        v-if="showSceenic && sceenicOrientation == 'vertical'"
        class="relative p-5 md:col-span-4 lg:col-span-3 bg-gray-800"
      >
        <NicknameInput v-if="!validated" @validate="validate"></NicknameInput>
        <template v-else>
          <div class="py-2 text-right hidden md:block">
            <Button
              type="button"
              :size="sm"
              :color="base"
              float="right"
              class="mb-3"
              @click="setSceenicOrientation('horizontal')"
              >{{ $t("live.button.horizontal") }}</Button
            >
            <a
              href="#"
              class="hidden md:block"
              @click="$emit('toggle-sceenic')"
            >
              <svg
                class="h-6 w-6 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </div>
          <div class="flex flex-wrap md:flex-col md:space-y-2 clear-both">
            <div
              v-for="participant in participantsRef"
              :key="participant.participantId"
              class="w-1/2 md:w-auto self-center"
            >
              <Video
                :participant-id="participant.participantId"
                :participant-name="participant.participantName"
                :is-muted="isMuted"
                :is-video-enabled="isVideoEnabled"
                :is-remote-audio-enabled="isRemoteAudioEnabled"
                :is-remote-video-enabled="isRemoteVideoEnabled"
                :is-local="participant.isLocal"
                :stream="participant.stream"
                video-class="object-cover object-center border border-grey-800"
                bubble
                @toggle-audio="toggleAudio"
                @toggle-video="toggleVideo"
                @toggle-remote-audio="toggleRemoteAudio"
                @toggle-remote-video="toggleRemoteVideo"
                @shareScreen="shareScreen"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@/components/Button.vue";
import Video from "@/components/Video.vue";
import NicknameInput from "@/components/NicknameInput.vue";
import LiveLike from "@livelike/engagementsdk";
import useTickets from "@/composables/use-tickets";
import useWatchTogether from "@/composables/use-watch-together";
import { ref, toRefs, watch } from "vue";
import { VideoIcon, MessageSquareIcon } from "@zhuowenli/vue-feather-icons";
export default {
  components: {
    Video,
    NicknameInput,
    Button,
    VideoIcon,
    MessageSquareIcon
  },
  props: {
    ticket: {
      type: Object,
      default: null
    },
    showChat: {
      type: Boolean,
      default: true
    },
    showSceenic: {
      type: Boolean,
      default: false
    }
  },
  emits: ["toggle-chat", "toggle-sceenic"],
  setup(props) {
    const {
      toggleAudio,
      toggleVideo,
      toggleRemoteAudio,
      toggleRemoteVideo,
      shareScreen,
      startConnecting,
      participantsRef,
      isMuted,
      isVideoEnabled,
      isRemoteAudioEnabled,
      isRemoteVideoEnabled
    } = useWatchTogether();

    const { getVideoId } = useTickets();

    const videoClass = ref("md:col-span-8 lg:col-span-9");
    const sceenicOrientation = ref("vertical");

    const validated = ref(false);

    // Handle dynamic layout changes
    var showChat = toRefs(props).showChat;
    var showSceenic = toRefs(props).showSceenic;
    const initVideoClass = function() {
      if (
        (!showChat.value && !showSceenic.value) ||
        (showSceenic.value && sceenicOrientation.value === "horizontal")
      ) {
        videoClass.value = "col-span-12";
      } else {
        videoClass.value = "md:col-span-8 lg:col-span-9";
      }
    };
    watch([showChat, showSceenic], initVideoClass);

    function setSceenicOrientation(orientation) {
      sceenicOrientation.value = orientation;
      initVideoClass();
    }

    function validate(value, name) {
      validated.value = value;
      LiveLike.init({
        clientId: props.ticket.chat.client_id,
        nickName: name
      });

      startConnecting(
        props.ticket.sceenic_token.fullToken,
        props.ticket.sceenic_token.url,
        props.ticket.sceenic_token.room,
        name
      );
    }

    return {
      toggleAudio,
      toggleVideo,
      toggleRemoteAudio,
      toggleRemoteVideo,
      shareScreen,
      participantsRef,
      isMuted,
      isVideoEnabled,
      isRemoteAudioEnabled,
      isRemoteVideoEnabled,
      getVideoId,
      videoClass,
      sceenicOrientation,
      setSceenicOrientation,
      validated,
      validate
    };
  }
};
</script>
<style scoped>
.chat-container {
  height: calc(100vh - 24rem);
}
.embed-container {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  background: black;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.vimeo-wrapper {
  padding: 56.25% 0 0 0;
  position: relative;
}
.vimeo-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
iframe form {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
@media screen and (min-width: 640px) {
  .chat-container {
    height: calc(100vh - 30rem);
  }
}
@media screen and (min-width: 768px) {
  .embed-container,
  .chat-container {
    height: calc(100vh - 93px);
  }
  .embed-container.horizontal {
    height: calc(100vh - 260px);
  }
}
</style>
