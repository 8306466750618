<template>
  <div class="bg-gray-800 bg-center bg-cover min-h-screen">
    <NavBar :container="false">
      <div class="flex items-stretch h-full">
        <a
          class="hidden md:block p-6 sm:p-8"
          :class="showChat ? 'bg-gray-800 text-white' : ''"
          href="#"
          @click.prevent="toggle('chat')"
        >
          <message-square-icon size="1.8x"></message-square-icon>
        </a>
        <a
          v-if="ticket.type === 'ROOM'"
          class="hidden md:block p-6 sm:p-8"
          :class="showSceenic ? 'bg-gray-800 text-white' : ''"
          href="#"
          @click.prevent="toggle('sceenic')"
        >
          <video-icon size="1.8x"></video-icon>
        </a>
        <ShareLink
          v-if="ticket.evt"
          :key="ticket.evt.slug"
          :url="
            $root.getFullRouteUrl({
              name: 'Concert',
              params: { slug: ticket.evt.slug }
            })
          "
          :title="ticket.evt.title"
          :key-id="ticket.evt.id"
          icon-size="1.8x"
          element-class="p-6 sm:p-8"
        />
      </div>
    </NavBar>
    <SuspenseWithError>
      <template #default>
        <AlertMessage
          v-if="ticket.type === 'VIP' || error != null"
          type="danger"
        >
          <template #message>
            <slot name="error"
              ><strong>{{ $t("live.error.message1") }}</strong
              >. {{ $t("live.error.message2") }}</slot
            >
          </template>
        </AlertMessage>
        <ticket-provider
          v-else
          :ticket="ticket"
          :show-chat="showChat"
          :show-sceenic="showSceenic"
          @toggleChat="toggle('chat')"
          @toggleSceenic="toggle('sceenic')"
        />
      </template>
      <template #fallback>
        <span>{{ $t("live.loading") }}</span>
      </template>
      <template #error>
        <slot name="error">{{ error }}</slot>
      </template>
    </SuspenseWithError>
  </div>
</template>
<script>
import TicketProvider from "@/components/tickets/TicketProvider.vue";
import NavBar from "@/components/NavBar.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import { VideoIcon, MessageSquareIcon } from "@zhuowenli/vue-feather-icons";
import ShareLink from "@/components/ShareLink.vue";
import SuspenseWithError from "@/components/SuspenseWithError.vue";
import useTickets from "@/composables/use-tickets";
import router from "@/router";
import { onMounted, ref } from "vue";
export default {
  components: {
    VideoIcon,
    MessageSquareIcon,
    SuspenseWithError,
    ShareLink,
    NavBar,
    TicketProvider,
    AlertMessage
  },
  setup() {
    const { getTicket, ticket, error } = useTickets();
    const { currentRoute } = router;
    const showChat = ref(false);
    const showSceenic = ref(true);
    onMounted(async () => await getTicket(currentRoute.value.params.id));
    function toggle(element) {
      switch (element) {
        case "chat":
          showChat.value = !showChat.value;
          showSceenic.value = false;
          break;
        case "sceenic":
          showSceenic.value = !showSceenic.value;
          showChat.value = false;
          break;
      }
    }
    return { ticket, error, showChat, showSceenic, toggle };
  }
};
</script>
