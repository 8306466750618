import Room from "@/components/tickets/Room.vue";
import Standard from "@/components/tickets/Standard.vue";
import VIP from "@/components/tickets/VIP.vue";
import Ticket from "@/components/tickets/Ticket.vue";

const COMPONENT_MAP = {
  ROOM: Room,
  STD: Standard,
  VIP: VIP,
  TICKET: Ticket
};

export function getComponent(type) {
  return COMPONENT_MAP[type];
}
