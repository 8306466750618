import client from "api-client";
import { SEND_MESSAGE_MUTATION } from "../graphql/messages";

export default {
  sendMessage(firstName, lastName, email, message) {
    return client.mutate({
      mutation: SEND_MESSAGE_MUTATION,
      variables: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        message: message
      }
    });
  }
};
