<template>
  <section class="my-10">
    <div
      class="bg-white rounded-t-2xl md:col-span-3 flex flex-wrap md:flex-nowrap items-center py-6 md:py-4 px-6 md:pr-5 space-y-4 md:space-y-0 md:space-x-8 bg-gray-100 shadow-lg"
    >
      <div class="flex-grow self-center min-w-full sm:min-w-0">
        <h2 class="text-violet-200 text-2xl font-semibold">
          {{ offer.name }}
        </h2>
        <h3 v-if="offer.summary" class="text-violet-200 text-lg font-semibold">
          ({{ offer.summary }})
        </h3>

        <p class="uppercase font-gray-700">
          {{ displayDate }}
        </p>
      </div>
      <div class="flex-none self-center sm:text-right">
        <p class="text-gold-300">{{ offer.price }} €</p>
      </div>
      <div class="flex-none self-center pl-5">
        <MinusCircleIcon
          class="inline align-middle cursor-pointer"
          @click="removeTicket()"
        />
        <span class="px-5 w-5">{{ quantity }}</span>
        <PlusCircleIcon
          class="inline align-middle cursor-pointer"
          @click="addTicket()"
        />
      </div>
    </div>
    <div
      class="bg-violet-200 text-white rounded-b-2xl px-6 py-6 md:py-4 text-sm shadow-lg"
    >
      <p>
        <a href="" @click.prevent="toggleDescription()">
          {{ $t("ticketCard.details") }}
          <ChevronUpIcon v-if="displayDescription" size="1.5x" class="inline" />
          <ChevronDownIcon v-else size="1.5x" class="inline" />
        </a>
      </p>
      <div v-if="displayDescription">
        <hr class="my-2" />
        {{ offer.description }}
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import {
  PlusCircleIcon,
  MinusCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from "@zhuowenli/vue-feather-icons";

export default {
  name: "TicketCard",
  components: {
    PlusCircleIcon,
    MinusCircleIcon,
    ChevronDownIcon,
    ChevronUpIcon
  },
  props: {
    offer: {
      type: Object,
      required: true
    },
    displayDate: {
      type: String,
      required: true
    }
  },
  emits: ["add-to-cart", "remove-from-cart"],
  setup(props, context) {
    const quantity = ref(0);
    const displayDescription = ref(false);
    function toggleDescription() {
      displayDescription.value = !displayDescription.value;
    }
    function addTicket() {
      quantity.value += 1;
      context.emit("add-to-cart", props.offer.id);
    }
    function removeTicket() {
      if (quantity.value > 0) {
        quantity.value -= 1;
        context.emit("remove-from-cart", props.offer.id, true);
      }
    }
    return {
      quantity,
      displayDescription,
      toggleDescription,
      addTicket,
      removeTicket
    };
  }
};
</script>
